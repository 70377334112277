.detail-page{
    padding: 100px 50px 20px 50px;
}
.row-detail-partner-container{
    display: flex;
    justify-content: left;
    align-items: center;
}
.row-detail-partner{
    display: inline-block;
    margin: 0px 15px;
    border-bottom: 5px solid #80808000;
    cursor: pointer;
    font-size: 20px;
}
.row-detail-partner-selected{
    padding: 5px 0px;
    border-bottom-color: #1d7aeb;
    color: #1d7aeb;
}
.box1{
    width: 50%;
    min-width: 250px;
    text-align: left;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
    padding: 20px;
    display: inline-block;
}
.box1-title1{
    font-weight: bold;
    font-size: 20px;
}
.box1-body{
    margin-top: 10px;
}
.box1-heading1{
    display: inline-block;
    width: 100px;
    font-weight: bold;
    color: rgb(150, 150, 150);
}
.box1-detail1{
    margin-left: 20px;
    display: inline-block;
    flex: 1 1 0%;
}
.box1-i1{
    display: flex;
}
.box1-i2{
    margin-top: 5px;
}
.box-container{
    display: flex;
    margin-top: 20px;
}
.back-div{
    text-align: left;
    cursor: pointer;
    color: gray;
    font-size: 17px;
}
.tabs1-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(239, 239, 239);
    border: 1px solid gray;
    border-radius: 10px;
}
.tabs1-div{
    padding: 5px 10px;
    cursor: pointer;
}
.tabs1-outer{
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}
.tabs1-selected{
    background-color: #022964;
    color: white;
    border-radius: 10px;
}


.sidebar{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 220px;
    /* margin-left: 50px; */
    border-right: 2px solid #e9f7f6;
    /* background-color: #e6f6f5; */
    background-image: linear-gradient(#e6f6f500, #e6f6f5);
}
.pwc-logo{
    height: 52px;
    margin: 30px 15px 0px 15px;

}
.profile-sidebar{
    margin: 50px 15px 0px 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #c3e4e1;
    line-break: anywhere;
    color: #000000;
    font-size: 15px;
}
.item-sidebar{
    padding: 0px 15px;
    margin: 13px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 10px;
    font-size: 15px;
    cursor: pointer;
}
.item-sidebar-selected{
    color: #25ab9d;
    font-weight: bold;
}
.item-sidebar-1{
    margin-top: 40px;
}
.bottom-sidebar{
    position: absolute;
    bottom: 0px;
    width: 190px;
    border-top: 1px solid #c3e4e1;
    margin: 0px 15px;
    padding: 25px 0px;
}
.bottom-sidebar-item-1{
    margin-bottom: 15px;
}
.table-div{
    margin-left: 220px;
    padding: 40px 20px 40px 40px;
}
.table-div-2{
    margin-left: 220px;
    padding: 30px 20px 40px 40px;
    height: 100vh;
    overflow-y: scroll;
}
.heading-div{
    display: flex;
    align-items: center;
    gap: 0px 20px;
}
.heading-1{
    font-size: 25px;
    font-weight: bold;
    margin-right: auto;
    color: black;
}
.export-csv-btn{
    background-color: #1c1b4b;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 10px;
    min-width: max-content;
}
.export-csv-btn-dlt{
    background-color: #e61313;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0px 10px;
    min-width: max-content;
}
.table-container{
    margin-top: 40px;
    /* width: 100%;
    height: 50%; */
}

.heading-div-left{
    margin-right: auto;
}
.navigation-path-1{
    font-size: 15px;
    color: #25ab9d;
    display: inline-block;
}
.navigation-path-2{
    font-size: 15px;
    color: gray;
    display: inline-block;
}
.account-name{
    font-size: 25px;
    font-weight: bold;
    color: #040404;
    margin-top: 10px;
}
.tab-category{
    margin-top: 30px;
    border-bottom: 2px solid rgb(215, 215, 215);
}
.tab-category-table{
    margin-top: 40px;
}
.tab-category-item{
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    color: #1d1b4c;
    padding: 10px 15px;
    cursor: pointer;
    /* border: 1px solid black; */
}
.tab-category-item-selected{
    color: #25ab9d;
    outline: 2px solid rgb(255, 255, 255); 
    border-top: 2px solid rgb(215, 215, 215);
    border-right: 2px solid rgb(215, 215, 215);
    border-left: 2px solid rgb(215, 215, 215);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* background-color: white;
    margin-bottom: -2px;
    border-bottom: none; */
}
.partners-container{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px 25px;
}
.partner-item{
    display: inline-block;
    width: 100px;
    height: 40px;
    border: 2px solid rgb(215, 215, 215);
    border-radius: 5px;
    /* margin-right: 25px; */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.partner-item-selected{
    border: 3px solid #25ab9d;
}
.account-info{
    /* display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px 20px; */
    
    /* display: none; */
    /* width: 100%; */
    font-size: 15px;

    padding: 17px 0px;
    border-radius: 5px;
    background-image: linear-gradient(to right ,#1b1a4b, #6e68b0);
    margin-top: 15px;
    overflow-x: scroll;
}
.partner-info{
    margin-top: 40px;
}
.account-info-item{
    /* min-width: 200px; */
    vertical-align: top;
    /* display: flex; */
    padding: 10px 30px;
}
.account-info-item-div{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px 7px;
    /* width: max-content; */
}
.account-info-column{
    margin-right: 70px;
}
.account-info-item-top{
    margin-bottom: 20px;
}
.account-info-item-heading{
    color: white;
    display: inline-block;
    width: max-content;
}
.account-info-item-text{
    color: white;
    font-weight: bold;
    display: inline-block;
    max-width: 300px;
}
.account-info-icon{
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.table-row-header{
    background-color: #f5f6f8;
    height: 60px !important;
}
.table-cell{
    /* background-color: #f5f6f8; */
    color: #12123b;
    font-weight: bold;
    font-size: 15px;
    /* height: 60px !important; */
    border-bottom: 1px solid #f0f3f4;
    text-align: start;
}
.table-row-body{
    height: 60px !important;
    border-bottom: 1px solid #f0f3f4;
}
.table-row-hover{
    cursor: pointer;
}
.table-1{
    width: 100%;
    margin-top: 30px;
}
.table-cell-body{
    color: #12123b;
    font-size: 15px;
    /* text-align: center; */
    /* height: 60px !important; */
}
th, td{
    padding: 5px 10px;
    /* height: 60px;
    overflow: hidden; */
}
.partner-table{
    overflow-x: scroll;
}
.pagination{
    text-align: right;
    margin-top: 15px;
}
.pagination-dot{
    display: inline-block;
}
.pagination-btn{
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    /* padding: 10px; */
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0);
    font-weight: bold;
}
.pagination-next, .pagination-prev{
    display: inline-block;
    background-color: rgba(255, 255, 255, 0);
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
.pagination-btn-selected{
    background-color: #1d1b4c;
    color: white;
}
.table-cell-body-div-success{
    background-color: #d0fad4;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    width: 55px;
    width: 115px;
}
.table-cell-body-div-failed{
    background-color: #ffd9da;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    width: 115px;
}
.table-cell-body-div-review{
    background-color: #d2d0d0;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    width: 55px;
    width: 115px;
}
.table-cell-body-div{
    /* height: 60px !important; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* text-overflow: "----";  */
    white-space: nowrap;
    max-width: 400px;
    cursor: pointer;
    word-wrap: break-word;
    /* min-width: 200px; */
    width: max-content;
}
.export-csv-btn-downloading{
    opacity: 0.7;
}
.navigation-path-back{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0px 5px;
    font-size: 18px;
    font-weight: bold;
}
.back-icon{
    height: 30px;
}
.analysis-data{
    display: flex;
    justify-content: end;
    margin-top: 10px;
    align-items: flex-end;
    position: relative;
    top: 20px;
    gap: 0px 10px;
}
.error-table{
    margin-top: 10px;
    font-size: 18px;
}
.sortable-cell{
    cursor: pointer;
}
.sorting-arrow{
    height: 15px;
    transform: rotate(90deg);
    display: inline-block;
    margin-left: 5px;
}
.sorting-arrow-down{
    transform: rotate(270deg);
}
.header-cell-div{
    display: flex;
    align-items: center;
}
.info-small{
    font-size: 12px;
    color: red;
    margin-right: auto;
}
.header-cell-div-cs{
    min-width: 160px;
}
.header-cell-div-cr{
    min-width: 120px;
}
.dates-block{
    margin: 5px 0px;
}
.error-div{
    font-size: 18px;
    margin-top: 10px;
    color: red;
}
.error-msg-div{
    margin-top: 10px;
    color: rgb(0, 0, 0);
}
.error-div-1{
    font-size: 20px;
    margin-top: 40px;
    color: red;
}
.search-div{
    cursor: pointer;
    position: relative;
    height: 36px;
}
.search-input{
    width: 20px;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('./images/search.svg');
    background-position: 10px 10px; 
    background-repeat: no-repeat;
    padding: 12px 20px 12px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    /* position: absolute;
    right: 0px */
    background-position: 10px 7px;
    padding: 7px 20px 7px 40px;
  } 
  .search-input:focus{
    width: 100%;
  }
  .search-icon-div{
    position: absolute;
    top: 0px;
    right: 0px;
    /* border: 1px solid #1c1b4b; */
    border-left: 1px solid rgb(89, 89, 89);
    padding: 10px 5px;
    height: 37px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-input-1{
    height: 100%;
    height: 37px;
    border: 1px solid rgb(88, 88, 88);
    /* border-bottom-left-radius: 5px;
    border-top-left-radius: 5px; */
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px 35px 5px 5px;
    width: 260px;
  }
  .search-input-1:focus-visible{
    outline: 2px solid #25ab9d;
    border: 1px solid rgba(88, 88, 88, 0);
  }

.fp-container{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001;
}
.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
}
/* .fp-container .fp-loader {
    top: 45%;
    left: 45.5%;
    z-index: 1000;
    position: absolute;
} */
.fp-loader{
    position: static !important;
    /* z-index: 10000; */
}
.review-heading-1{
    margin-top: 40px;
    font-size: 18px;
}
.timeline-outer-div{
    margin-top: 50px;
}
.timeline-partners-name{
    margin-top: 5px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 1.6;
}
.timeline-block-incomplete{
    margin-top: 15px;
}
.timeline-block-incomplete-top{
    margin-top: 0px;
}
.error-msg-object{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.error-msg-object-key{
    white-space: nowrap;
}
.show-cursor-history{
    cursor: pointer;
}
.error-message-object:first-child{
    margin-top: 5px;
}
.dopdown-id-div{
    margin: 30px 0px;
}
.dropdown-label{
    font-weight: bold;
}
.dropdown-id-list{
    height: 30px;
    min-width: 150px;
    margin-left: 20px;
    padding: 0px 10px;
}
.navigation-path-clickable{
    color: #25ab9d;
    cursor: pointer;
    font-weight: bold;
}
.download-pdf-btn{
    margin-left: 20px;
}
.lds-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.65);
    top: 0px;
    left: 0px;
}
.lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #25ab9d;
    animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    animation-delay: -1.6s;
  }
  @keyframes lds-grid {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }