.categeory-conatiner {
    margin-bottom: 20px;
}

.partners-list-partners-n {
    grid-gap: 60px 10px;
    grid-gap: 40px 10px;
    grid-gap: 40px 30px;
    grid-gap: 40px 20px;
    align-content: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-template-columns: repeat(auto-fill, 220px);
    grid-template-columns: repeat(auto-fill, 200px);
    grid-template-columns: repeat(auto-fill, 100px);
    grid-template-columns: repeat(auto-fill, 180px);
    grid-template-columns: repeat(auto-fill, 120px);
    justify-content: space-around;
    justify-content: flex-start;
    padding: 20px 20px 10px 10px;
}

.partner-image-n {
    height: 80px;
    height: 40px;
    height: auto;
    width: 150px;
    width: 135px;
    width: 100px;
}

img,
svg {
    vertical-align: middle;
}

.partner-container-n {
    align-items: center;
    background-color: #ffffff00;
    background-color: var(--partner-container-partner-bgc);
    border: 1px solid #0006;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    height: 54px;
    height: 120px;
    height: 90px;
    height: 80px;
    height: 60px;
    justify-content: space-between;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 250px;
    width: 220px;
    width: 200px;
    width: 100px;
    width: 180px;
    width: 120px;
}

.partner-container-n-showing {
    border: 1px solid rgba(0, 0, 255, .745);
    outline: 3px solid rgba(0, 0, 255, .47);
}

.remove-icon-n,
.selected-icon-n {
    height: 18px;
    position: absolute;
    width: 18px;
}

.selected-icon-n {
    height: 20px;
    left: 167px;
    left: -9px;
    top: -12px;
    top: -9px;
    width: 20px;
}

.partner-page-form-n {
    min-height: 100%;
    position: relative;
}

.title-p {
    text-align: center;
}

.title-p {
    text-align: left;
}

.title-p-n {
    text-align: left !important;
}

.partners-list-heading-n,
.title-p-n {
    font-size: 20px;
    font-size: 23px;
    font-weight: 600;
}

.scl {
    display: inline-block;
    width: 285px;
}

.scl {
    font-weight: 500;
    padding-bottom: 5px;
    vertical-align: top;
}

.input-p {
    display: inline-block;
    width: 350px;
}

.info-p-n-m {
    margin-top: 3rem !important;
}

.form-p {
    margin-top: 40px;
}

.form-control {
    display: block;
    width: 100%;
    /* padding: 0.375rem 0.75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.ddrop-div {
    border: 2px dashed gray;
    /* padding: 10px 0; */
    text-align: center;
}

.btn-div-p-n {
    bottom: 10px;
    position: absolute;
}

.ddrop-div {
    /* background: transparent; */
    text-align: center;
    /* padding: 10px 0; */
    border: 2px dashed gray;
}

.p-image img{
    width: inherit;
    padding: 10px
}

.p-image-preview{
    max-width: 80vw !important;
}

.title{
    margin-bottom: -0.5rem !important;
}

.superTitle{
    margin-bottom: 2rem !important;
}

.dataSection{
    margin-bottom: 1rem !important;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #ffffff !important;
}
.littleTitle{
    font-size: medium;
}

.address{
    padding: 0.2rem;
}

.table thead tr th{
    font-size: medium !important;
    /* padding-left: 0rem !important; */
}
.table tbody tr td h6{
    /* font-size: small !important; */
    font-size: medium !important;
    /* padding-left: 0rem !important; */
    padding: 0rem .6rem;
}

.p-image-toolbar{
    background-color: #6d68af;
    border-radius: 21px 21px 21px 21px;
    margin: 1rem;
}

.p-link:focus {
    outline: none;
    box-shadow: none;
}

